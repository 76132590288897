<template>
    <div fill-height class="d-flex align-stretch flex-grow-1 headermargin">
        <DeleteOverlay :deleteOverlay="deleteOverlay" :deleteItem="deleteItem" @changeDeleteOverlay="deleteOverlay = false"/>
        <v-row no-gutters fill-height class="pink">
            <v-col :cols="leftColumn"  v-if="leftColumn > 0" class="grey lighten-2 text-left" fill-height>
                <div class="d-flex justify-space-between grey darken-2 pa-5 align-center">
                    <div><h1 class="white--text">Trains</h1></div>
                    <v-spacer />
                    <div class="white--text mr-5 d-flex align-center"><div class="mr-3">Show Disabled</div><div><v-switch color="white" v-model="showDisabled" inset></v-switch></div></div>
                    <div v-if="$vuetify.breakpoint.mdAndUp" class="mr-4"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>
                    <div><v-btn large class="primary white--text ml-0" depressed @click="MIX_formMode = 'Add', form = defaultForm; MIX_panel('show');">Add <span v-if="$vuetify.breakpoint.mdAndUp">Train</span></v-btn></div>
                </div>
                <div v-if="$vuetify.breakpoint.smAndDown" class="grey darken-2 pb-4 px-5"><v-text-field dense solo clearable background-color="white" v-model="search"  label="Search" hide-details="auto"></v-text-field></div>                
                <!-- <v-data-table :search="search" :headers="headers" :items="documents" :items-per-page="20" class="elevation-1" >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn class="darken-3 ml-0" depressed @click="MIX_formMode='Edit'; editDocument(item.id)"><v-icon class="black--text">mdi-pencil</v-icon></v-btn>
                        <v-btn class="darken-3 ml-1" depressed @click="FB_deleteDocument(item.id)"><v-icon class="black--text">mdi-delete</v-icon></v-btn>
                    </template>                
                </v-data-table> -->
                    <!-- <v-tab @click="zone = ''">All Zones</v-tab> -->
                <v-tabs background-color="grey lighten-3" color="primary">
                    <v-tab v-for="item in zones" @click="zone = item.id" :key="item.id">{{ item.zoneName }}</v-tab>
                </v-tabs>
                <v-data-table :headers="headers" :items="computedTrains" :search="search" :items-per-page="10" class="elevation-1" >
                    <template v-slot:[`item.rank`]="{ index }" v-if="!showDisabled">
                        <v-avatar v-if="index <  3" color="primary" size="30" class="white--text" >{{ index+1 }}</v-avatar>
                    </template>
                    <template v-slot:[`item.date`]="{ item }">
                        {{ $moment(item.date, 'X').format('DD/MM HH:mm') }} 
                    </template>
                    <template v-slot:[`item.enabled`]="{ item }">
                        <v-switch color="primary darken-1" :input-value="item.enabled" @click="disableTrain(item)" inset></v-switch>
                    </template>
                    <template v-slot:[`item.delayed`]="{ item }">
                        <v-switch color="primary darken-1" :input-value="item.delayed" @click="delayDialog = true, selectedTrain = item" inset></v-switch>
                    </template>                    
                    <template v-slot:[`item.loading`]="{ item }">
                        <v-switch color="primary darken-1" :input-value="item.loading" @click="loadingTrain(item)" inset></v-switch>
                    </template>                                        
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn class="darken-3 ml-0" depressed @click="MIX_formMode='Edit'; editDocument(item.id)"><v-icon class="black--text">mdi-pencil</v-icon></v-btn>
                        <v-btn class="darken-3 ml-1" depressed @click="deleteOverlay = true, deleteItem.id = item.id, deleteItem.collectionName = collectionName"><v-icon class="black--text">mdi-delete</v-icon></v-btn>
                    </template>
                </v-data-table>    
                <v-dialog
                    v-model="delayDialog"
                    width="600"
                >
                    <v-card class="pa-2">
                        <v-col cols="12">
                            <v-card-title>Please enter a new time for {{ selectedTrain ? selectedTrain.destination : '' }} on Platform {{ selectedTrain ? selectedTrain.platform : '' }}</v-card-title>
                            <v-row>
                                <v-col cols="6">
                                    <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto" >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field :rules="requiredRules" background-color="white" filled hide-details="auto" v-model="computedDateFormatted" label="Date" persistent-hint readonly v-bind="attrs" v-on="on" ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date" no-title @input="menu2 = false" ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete :rules="requiredRules" background-color="white" hide-details="auto" filled label="Hr" :items="hours" v-model="hour"></v-autocomplete>
                                </v-col>    
                                <v-col cols="3">                    
                                    <v-autocomplete :rules="requiredRules" background-color="white" hide-details="auto" filled label="Min" :items="mins" v-model="minute"></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn @click="delayDialog = false">CLOSE</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn v-if="selectedTrain ? selectedTrain.delayed === true : ''" class="primary" @click="delayTrain(selectedTrain)">REMOVE DELAY TIME</v-btn>
                                    <v-btn v-else class="primary" @click="delayTrain(selectedTrain)">DELAY</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-dialog>            
            </v-col>
            <v-col :cols="rightColumn" v-if="rightColumn > 0" class="grey lighten-3">
                <div class="d-flex align-center justify-space-between grey darken-3 pa-5 py-7">
                    <div><h1 class="white--text">{{ MIX_formMode }} Train</h1></div>
                    <div><v-btn large class="grey darken-3 white--text ml-0" depressed @click="MIX_cancelDocument()"><v-icon>mdi-close</v-icon></v-btn></div>
                </div>
                <v-form ref="zoneForm" class="pa-5">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete :rules="requiredRules" background-color="white" v-model="form.zone" :items="zones" item-text="zoneName" item-value="id" filled label="Zone" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :rules="requiredRules" background-color="white" v-model="form.destination" :items="destinations" item-text="destinationName" item-value="destinationName" filled label="Destination" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :rules="requiredRules" background-color="white" v-model="form.pattern" :items="patterns" item-text="patternName" item-value="patternName" filled label="Calling Pattern" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true" @change="getPatternStations()"></v-autocomplete>
                            <VTextMarquee v-if="form.pattern !== ''" class="mt-5" :speed="40" :content="patternStations"></VTextMarquee>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :rules="requiredRules" background-color="white" v-model="form.platform" :items="platforms" filled label="Platform" hide-details="auto" :menu-props="{ top: false, offsetY: true }" :attach="true"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto" >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :rules="requiredRules" background-color="white" filled hide-details="auto" v-model="computedDateFormatted" label="Date" persistent-hint readonly v-bind="attrs" v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker v-model="date" no-title @input="menu2 = false" ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="3">
                            <v-autocomplete :rules="requiredRules" background-color="white" hide-details="auto" filled label="Hr" :items="hours" v-model="hour"></v-autocomplete>
                        </v-col>    
                        <v-col cols="3">                    
                            <v-autocomplete :rules="requiredRules" background-color="white" hide-details="auto" filled label="Min" :items="mins" v-model="minute"></v-autocomplete>
                        </v-col>
                        <v-col cols="6" class="text-left">
                            <v-btn large class="grey lighten-1 white--text ml-0" depressed @click="MIX_cancelDocument()">Cancel</v-btn>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <v-btn v-if="MIX_formMode === 'Add'" large class="primary white--text ml-0" depressed @click="createDocument(collectionName,form)">Save</v-btn>
                            <v-btn v-if="MIX_formMode === 'Edit'" large class="primary white--text ml-0" depressed @click="FB_updateDocument(collectionName,form)">Update</v-btn>
                        </v-col>
                    </v-row>
                    <!-- <pre>{{ form }}</pre>
                    <pre>{{ destinations }}</pre> -->
                </v-form>          
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        requiredRules: [
            v => !!v || 'This is required',
        ],
        deleteOverlay: false,
        deleteItem: {
            id: '',
            collectionName: '',
        },
        delayDialog: false,
        form: {
            destination: '',
            zone: '',
            platform: '',
            date: '', 
            pattern: '',
            enabled: true,
            delayed: false,
            loading: false,
            delayedTime: ''
        },
        defaultForm: {
            destination: '',
            zone: '',
            platform: '',
            date: '', 
            pattern: '',
            enabled: true,
            delayed: false,
            loading: false
        },
        formMode: '',
        loader: null,
        loading: null,
        zone: 'ZONE1',
        hour: '00',
        minute: '00',
        collectionName: 'trains',
        platforms: ['-','0','1','2','3','4','5','6','7','8','9','10'],
        hours: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'],
        minutes: ['00','05','10','15','20','25','30','35','40','45','50','55'],
        headers: [
            { text: 'Rank', value: 'rank' },
            { text: 'Zone', value: 'zone' },
            { text: 'Destination', value: 'destination' },
            { text: 'Pattern', value: 'pattern' },
            { text: 'Platform', value: 'platform' },
            { text: 'Date', value: 'date' },
            { text: 'Enabled', value: 'enabled' },
            { text: 'Delayed', value: 'delayed' },
            { text: 'Loading', value: 'loading' },
            { text: '', value: 'actions', align: 'end' },
        ],
        destinations: [],
        zones: [],
        trains: [],
        patterns: [],
        patternStations: '',
        result: {},
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: '', //this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        menu2: false,
        search: '',
        showDisabled: false

    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
        computedFullDate() {
            return this.$moment(this.computedDateFormatted, 'DD/MM/YYYY').add({hours: this.hour, minutes: this.minute}).format('X')
        },
        computedTrains() {
            var trains = this.trains
            if (this.zone !== '') {
                trains = this.$_.filter(trains, (e) => { return e.zone === this.zone })
            }
            if (this.showDisabled) {
                trains = this.$_.filter(trains, (e) => { return e.enabled === false })
            } else {
                trains = this.$_.filter(trains, (e) => { return e.enabled === true })
            }
            trains = this.$_.sortBy(trains, 'date');    
            return trains //this.$_.take(trains, 3);
        },
        mins() {
            var mins = []
            for (var i = 0; i < 60; i++) {
                if (i < 10) {
                    mins.push ('0' + i).toString()
                } else {
                    mins.push (i.toString())
                }
            }
            return mins
        }, 
    },
    watch: {
        date () {
                this.dateFormatted = this.formatDate(this.date)
                this.form.date = this.computedFullDate
        },
        hour () {
                this.form.date = this.computedFullDate
        },
        minute () {
            this.form.date = this.computedFullDate
        },
        loader() {
            const l = this.loader;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 30000);
            this.loader = null;
        },
    },
    methods: {
        resetForm() {
            this.form.destination = '',
            this.form.zone = '',
            this.form.platform = '',
            this.form.date = '', 
            this.form.pattern = '',
            this.form.enabled = true,
            this.form.delayed = false
        },
        // cancelDocument: async function() {
        //     this.hour = '00'
        //     this.minute = '00'
        //     this.form = JSON.parse(JSON.stringify(this.defaultForm))
        //     this.formMode = '';
        // },
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null
            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        createDocument: async function() {
            var t = this;
            if(t.$refs.zoneForm.validate()) {
                var documentResult = await t.FB_createDocument(t.collectionName, t.form);
                t.result = documentResult;
                this.trains = await this.getDocuments('trains');
                this.resetForm()
            } else {
                t.MIX_alert(-1, "Please fill in all required fields.", null, null);
            }
        },
        updateDocument: async function() {
            var t = this;
            var documentResult = await t.FB_updateDocument(t.collectionName, t.form);
            t.result = documentResult;
            this.trains = await this.getDocuments('trains');
        },      
        editDocument: async function(id) {
            this.MIX_panel('show');
            var document = this.trains.find(e => e.id === id);
            console.log('document = ' + JSON.stringify(document, null, 2))
            if (document !== undefined) {
                this.form = JSON.parse(JSON.stringify(document));
            }
            this.getPatternStations();
            this.hour = this.$moment(document.date, 'X').format('HH');
            this.minute = this.$moment(document.date, 'X').format('mm');
        },
        deleteDocument: async function(id) {
            var t = this;
            console.log('deleteDocument ' + id);
            var documentResult = await t.FB_deleteDocument(t.collectionName, id);
            t.result = documentResult;
            this.deleteOverlay = false
            this.trains = await this.getDocuments('trains');
        },
        getPatternStations() {
            let t = this
            var patternStations = t.$_.find(t.patterns, function(e) { return e.id === t.form.pattern })
            this.patternStations = patternStations.patternStations
        },
        getDocuments: async function () {
                let t = this;
                var collection = t.$firebase.db.collection('trains')
                collection.onSnapshot(function (querySnapshot) {
                    t.trains = [];
                    querySnapshot.forEach(function (doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                          t.trains.push(document);
                        }
                        /*eslint-enables */
                    });
                });
        },          
        getDocumentsOther: function(collectionName) {
            return new Promise((resolve) => {
                let t = this;
                var collection = t.$firebase.db.collection(collectionName)
                collection.onSnapshot(function(querySnapshot) {
                    var documents = [];
                    querySnapshot.forEach(function(doc) {
                        var document = doc.data()
                        document.id = doc.id;
                        /*eslint-disable */
                        if (!document.hasOwnProperty('delete')) {
                            documents.push (document);
                        }
                        /*eslint-enables */
                    });
                    resolve (documents)
                })
            })
        },
        disableTrain(selectedTrain) {
            let t = this
            t.form.id = selectedTrain.id
            t.form.date = selectedTrain.date
            t.form.destination = selectedTrain.destination
            t.form.pattern = selectedTrain.pattern
            t.form.platform = selectedTrain.platform
            t.form.zone = selectedTrain.zone
            t.form.delayed = selectedTrain.delayed
            t.form.loading = selectedTrain.loading
            if(selectedTrain.enabled === true) {
                t.form.enabled = false
            } else {
                t.form.enabled = true
            }
            t.updateDocument()
        },
        delayTrain(selectedTrain) {
            let t = this
            t.form.id = selectedTrain.id
            t.form.destination = selectedTrain.destination
            t.form.pattern = selectedTrain.pattern
            t.form.platform = selectedTrain.platform
            t.form.zone = selectedTrain.zone
            t.form.enabled = selectedTrain.enabled
            t.form.loading = selectedTrain.loading
            if(selectedTrain.delayed === true) {
                t.form.delayed = false
                t.form.date = selectedTrain.date
            } else {
                t.form.delayed = true
                t.form.delayedTime = t.form.date;
            }
            t.updateDocument()
            t.delayDialog = false;
        },        
        loadingTrain(selectedTrain) {
            let t = this
            t.form.id = selectedTrain.id
            t.form.date = selectedTrain.date
            t.form.destination = selectedTrain.destination
            t.form.pattern = selectedTrain.pattern
            t.form.platform = selectedTrain.platform
            t.form.zone = selectedTrain.zone
            t.form.enabled = selectedTrain.enabled
            if(selectedTrain.loading === true) {
                t.form.loading = false
            } else {
                t.form.loading = true
            }
            t.updateDocument()
        }                


    },
    async mounted() {
        this.trains = await this.getDocuments();
        this.destinations = await this.getDocumentsOther('destinations');
        this.zones = await this.getDocumentsOther('zones');
        this.patterns = await this.getDocumentsOther('patterns');
        this.form.date = this.computedFullDate
    }
}
</script>