import { render, staticRenderFns } from "./zones.vue?vue&type=template&id=c200225e"
import script from "./zones.vue?vue&type=script&lang=js"
export * from "./zones.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports